import React from "react";

import Hero from "../components/Hero";
import HeroSponsors from "../components/HeroSponsors"
import ContentSponsors from "../components/ContentSponsors"
import Footer from "../components/Footer";
import FooterSponsors from "../components/FooterSponsors"
import Faq from "../components/Faq";


export default function Team() {
  return (
    <>
    <HeroSponsors />
    <ContentSponsors />
    <FooterSponsors />
    </>
  );
}
