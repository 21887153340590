import React, { useState } from 'react';

interface AccordionMenuProps {
    items: { title: string; content: React.ReactNode }[];
  }
  
  const AccordionMenu: React.FC<AccordionMenuProps> = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
  
    const handleItemClick = (index: number) => {
      if (activeIndex === index) {
        setActiveIndex(null);
      } else {
        setActiveIndex(index);
      }
    };
  
    return (
      <div className="accordion-container">
        {items.map((item, index) => (
          <div
            key={index}
            className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
          >
            <div
              className="accordion-item-title"
              onClick={() => handleItemClick(index)}
            >
              {item.title}
              {activeIndex === index ? '  ' : ' > '}
            </div>
            <div className="accordion-item-content">
              {item.content}
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default AccordionMenu;
  