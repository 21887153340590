import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import '../styles/sidebar.css';
import styled from 'styled-components';

export default function Sidebar() {
  return (
    <Menu right>
      <a className="menu-item" href="#info">
        About
      </a>
      <a className="menu-item" href="#register">
        Sign Up
      </a>
      <a className="menu-item" href="#schedule">
        Schedule
      </a>
      <a className="menu-item" href="#faq">
        FAQ
      </a>
      <a className="menu-item" href="#sponsors">
        Sponsors
      </a>
      {/* <a className="menu-item" href="#team">
        Our Team
      </a> */}
    </Menu>
  );
};