import React from "react";

import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Faq from "../components/Faq";


export default function Other() {
  return (
    <>
    <Faq />
    <Footer />
    </>
  );
}
