import React from "react";
import "./styles/global.css";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Content from "./components/Content";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import Other from "./pages/Other";
import Team from "./pages/Team";
import Sponsors from "./pages/Sponsors"

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="faq" element={<Other />} />
          <Route path="team" element={<Team />} />
          <Route path="sponsors" element={<Sponsors />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
