import React from "react";

import Hero from "../components/Hero";
import Footer from "../components/Footer";
import TheTeam from "../components/TheTeam";


export default function Team() {
  return (
    <>
    <TheTeam />
    <Footer />
    </>
  );
}
