import { Container } from "@mui/system";
import React from "react";
import Mailchimp from "./Mailchimp";
import AccordionMenu from "./AccordionMenu";
import parse from 'html-react-parser';

const items = [
  {
    title: 'Who can attend?',
    content: "Any high school student is welcome to apply! If you're in middle school, you may attend if you are at least 13 years old. Absolutely no coding experience is necessary.",
  },
  {
    title: "What if I've never been to a hackathon before?",
    content: 'You can let us be your first! In fact, HackMHS is designed with first time hackers in mind, with many mentors and workshops to guide you along the way!',
  },
  {
    title: 'How long will the event last?',
    content: 'HackMHS will start on Friday afternoon. The hacking period will be 24 hours.',
  },
  {
    title: 'Do students sleep overnight?',
    content: 'Students are encouraged, although not required, to stay overnight as part of the hackathon experience. There will be designated quiet areas for sleeping. If you are uncomfortable staying overnight, you may be picked up by a parent.',
  },
  {
    title: 'What should I bring?',
    content: 'Bring your laptop, phone, chargers, a sleeping bag (if desired), and excitement about learning new things!',
  },
  {
    title: 'How much does it cost?',
    content: "Nothing at all! HackMHS is completely free to all participants. While you're here, you'll get free food and merch",
  },
  {
    title: "I'm a parent concerned about safety. Is HackMHS safe?",
    content: "Yes! HackMHS is definitely safe. All 24 hours, there are adult chaperones on site monitoring the event around-the-clock. Along with that, Millburn High School is a safe location with night time security. Also, all students must follow the MLH Code of Conduct.",
  },
  {
    title: "Do I need to join a team beforehand?",
    content: "Nope! We encourage you to meet new students during team formation at the beginning of the event. We recommend you form a team of up to 4 students.",
  },
  {
    title: "Are you endorsed by MLH (Major League Hacking)?",
    content: "Though MLH is at capacity for this season, we are advised by MLH and we will still be following their guidelines and regularly communicating with them to plan hackMHS.",
  },
  {
    title: "What parts can my project use?",
    content: parse(`We'd love to see your projects come to life. As long as your project hardware follows our <a href="https://docs.google.com/document/d/1_AiXL0Z7yh-16PcIJLDno4QFWFOTXzoyYCBgL2qKm6c/edit#" target="_blank">hardware guidelines</a>, you're more than welcome to use it.`),
  }
];

export default function Content() {
  return (
    <div className="Content">
      <Container max-width="md">

        <div className="paragraph">
          <div className="spacer"></div>
          <h1 className="content-header" id="info">
            About
          </h1>
          <p className="content-text" id="infotext">
            hackMHS is a hackathon - a 24 hour invention marathon focused on
            growth, learning, innovation, and teamwork. Students of all
            backgrounds, new or experienced, are invited to create fun projects.
            Participants work together to turn their ideas into reality.
          </p>
          <p className="content-text">Make sure to familiarize yourself with the <a href="https://drive.google.com/file/d/1wr4F4oFkplHrXlJolp82WnSpNVTC9wgT/view?usp=sharing" target="_blank">code of conduct</a> before attending hackMHS.</p>
        </div>
        <hr />

        <div className="paragraph">
          <h1 className="content-header" id="register">
            Sign Up
          </h1>
          <p className="content-text">
            hackMHS will take place from May 19th, 5:00pm to around 8:00pm the next day. There will be 24 hours of hacking, food, workshops, and games. If you have any questions before you sign up, feel free to reach out to us at <a href="mailto:info@hackmhs.com">info@hackmhs.com</a>.
          </p>
        </div>
        <br />

        <div className="btn">
          <a href="https://form.jotform.com/230487371286058" target="_blank">
            <button className="button">
              <span className="button-content">Sign Up</span>
            </button>
          </a>
        </div>
        <hr />

        <div className="paragraph">
          <h1 className="content-header" id="schedule">
            Schedule
          </h1>
          <img id="scheduleimg" src="schedule.png" />
        </div>
        <br />

        <div className="paragraph">
          <h2 className="content-header" id="faq">FAQ</h2>
          <p className="content-text">
            We're sure you have many questions, so please look below for some answers. If anything is unanswered or you just want some clarification, give us an email at info@hackmhs.com
          </p>
          <AccordionMenu items={items} />
        </div>

        <hr />

        <div className="paragraph">
          <h1 className="content-header" id="sponsors">
            Sponsors
          </h1>
          <div className="content-text" id="sponsorstext">
            <div className="grid-container">
            <img id="sponsorimg" src="edfoundation.png" />
            <img id="sponsorimg" src="librehacks.png" />
            <img id="sponsorimg" src="clemenzas.png" />
            <img id="sponsorimg" src="penneng.png" />
            <img id="sponsorimg" src="luxelife.png" />
            <img id="sponsorimg" src="newfrontier.png" />
            </div>
          </div>
        </div>
        <div className="btn">
          <a
            href="https://donate.stripe.com/6oE9CV3L15yLf729AF"
            target="_blank"
          >
            <button className="button">
              <span className="button-content">Donate </span>
            </button>
          </a>
        </div>

        <hr />

        {/* <div className="paragraph">
          <h1 className="content-header" id="team">
            Our Team
          </h1>
          <p className="content-text" id="infotext">
            group photo
          </p>
        </div> */}

      </Container>
    </div>
  );
}
