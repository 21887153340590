import React from "react";
import { Outlet, Link } from "react-router-dom";
import Sidebar from './Sidebar';

export default function Navbar() {
  return (
    <>
      <nav className="navbar">
        <Link to="/" className="nav-link">
          <img src="logo_small.png" />{" "}
        </Link>
      </nav>
      <Sidebar />
      <Outlet />
    </>
  );
}
