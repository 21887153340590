import React from "react";

import "../styles/global.css";
import Hero from "../components/Hero";
import ContentNew from "../components/ContentNew";
import Footer from "../components/Footer";


export default function Home() {
  return (
    <>
      <div className="site-hero"></div>
      <Hero />
      <ContentNew />
      <Footer />
    </>
  );
}
