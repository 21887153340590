import { Container, Typography } from "@mui/material";
import { FaInstagram } from "react-icons/fa"
import React from "react";

export default function FooterSponsors() {
  return (
    <div className="FooterSponsors">
      <p>Contact us at <a href="mailto:sponsors@hackmhs.com">sponsors@hackmhs.com</a> for more information</p>
    </div>
  );
}
