import { Container, Typography } from "@mui/material";
import React from "react";

export default function HeroSponsors() {
  return (
    <div className="Hero">
      <div className="HeroText">
        <h3>welcome to</h3>
        <h1>
          Hack<span>MHS VIII</span>
        </h1>
        <h2 className="subheading">Sponsorship Info</h2>
      </div>
     
    </div>
  );
}
