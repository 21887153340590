import { Container, Typography } from "@mui/material";
import React from "react";

// const renderer = ({ hours:any, minutes, seconds, completed }) => {
//   if (completed) {
//     // Render a completed state
//     return <Completionist />;
//   } else {
//     // Render a countdown
//     return <span>{hours}:{minutes}:{seconds}</span>;
//   }
// };

export default function Hero() {
  return (
    <div className="Hero">
      <div className="HeroText">
        <h1>
          hack<span>MHS VIII</span>
        </h1>
        <h2>5.19.23 - 5.20.23</h2>
      </div>
    </div>
  );
}
