import { Container } from "@mui/system";
import React from "react";

export default function TheTeam() {
  return (
    <div className="Content">
      <Container max-width="md">
        <div className="paragraph">
          <h1 className="content-header" id="info">
            Meet the HackMHS organizing team!
          </h1>
        </div>
        <hr />

        <div className="paragraph">
          <h1 className="content-header" id="info">
            Leaders
          </h1>
          <p className="content-text" id="infotext">

          </p>
          <div className="card-grid">
          <div className="card">
              <h3 className="card-title-s">Joey Sorkin</h3>
              <p className="card-text-s">
              </p>
            </div>

            <div className="card">
              <h2 className="card-title-s">Saaz Mahadkar</h2>
              <p className="card-text-s">
              </p>
            </div>
            </div>
            <div className="card">
              <h2 className="card-title-s">Zayn Rekhi</h2>
              <p className="card-text-s">
              </p>
            </div>
        </div>
        <hr />

        <div className="paragraph">
          <h1 className="content-header" id="info">
            Finance
          </h1>
          <p className="content-text" id="infotext">
The finance team manages the funding, our budget, and our outreatch to potential sponsors.
          </p>
          <div className="card-grid">
          <div className="card">
              <h3 className="card-title-s">Kylan Chen</h3>
              <p className="card-text-s">
              </p>
            </div>

            <div className="card">
              <h2 className="card-title-s">Jia Panjwani</h2>
              <p className="card-text-s">
              </p>
            </div>

            <div className="card">
              <h2 className="card-title-s">Lexie Lee</h2>
              <p className="card-text-s">
              </p>
            </div>
            <div className="card">
              <h2 className="card-title-s">Sana Sinha</h2>
              <p className="card-text-s">
              </p>
            </div>
            </div>


        </div>
        <hr />

        <div className="paragraph">
          <h1 className="content-header" id="info">
            Logistics
          </h1>
          <p className="content-text" id="infotext">
The logistics team organizes and coordinates the schedule on the day of the event, making sure everything runs safely and smoothly.
          </p>
          <div className="card-grid">
            <div className="card">
              <h2 className="card-title-s">Adi Mittal</h2>
              <p className="card-text-s">
              </p>
            </div>

            <div className="card">
              <h2 className="card-title-s">David Man</h2>
              <p className="card-text-s">
              </p>
            </div>

            <div className="card">
              <h2 className="card-title-s">Alex Wong</h2>
              <p className="card-text-s">
              </p>
            </div>
            <div className="card">
              <h2 className="card-title-s">Richard Dong</h2>
              <p className="card-text-s">
              </p>
            </div>


            </div>
            <div className="card">
              <h2 className="card-title-s">Pragun Seth</h2>
              <p className="card-text-s">
              </p>
            </div>
        </div>
        <hr />
        <div className="paragraph">
          <h1 className="content-header" id="info">
            Marketing
          </h1>
          <p className="content-text" id="infotext">
            The marketing team coordinates outreach and advertisment campaigns to get more people interested in HackMHS. This team also creates the HackMHS logo and other graphics displayed on this website.
          </p>
          <div className="card-grid">
            <div className="card">
              <h2 className="card-title-s">Elise Samson</h2>
              <p className="card-text-s">
              </p>
            </div>

            <div className="card">
              <h2 className="card-title-s">Mae Subramanian</h2>
              <p className="card-text-s">
              </p>
            </div>
            </div>

        </div>
        <hr />
        <div className="paragraph">
          <h1 className="content-header" id="info">
            Website
          </h1>
          <p className="content-text" id="infotext">
            The website team manages the upkeep and development of this website and other tech-related projects at HackMHS.
          </p>

          <div className="card-grid">
            <div className="card">
              <h3 className="card-title-s">Hendry Xu</h3>
              <p className="card-text-s">
              </p>
            </div>

            <div className="card">
              <h2 className="card-title-s">Ritvik Gupta</h2>
              <p className="card-text-s">
              </p>
            </div>
        </div>
        </div>
        <hr />
        




      </Container>
    </div>
  );
}
