import { Container, Typography } from "@mui/material";
import { FaInstagram } from "react-icons/fa"
import React from "react";

export default function Footer() {
  return (
    <div className="Footer">
      <p>made with &hearts; by the hackmhs team</p>
      <a href="https://www.instagram.com/hackmhs/" target="_blank"><FaInstagram className="soc-icon"/></a>
    </div>
  );
}
