import { Container } from "@mui/system";
import React from "react";

export default function ContentSponsors() {
  return (
    <div className="Content">
      <Container max-width="md">
        <div className="paragraph">
          <h1 className="content-header" id="info">
            What is HackMHS?
          </h1>
          <p className="content-text" id="infotext">
          HackMHS is a hackathon - a 24 hour invention marathon focused on
            growth, learning, innovation, and teamwork. Students of all
            backgrounds, new or experienced, are invited to create fun projects.
          </p>
        </div>
        <hr />

        <div className="paragraph">
          <h1 className="content-header" id="info">
            Our Mission
          </h1>
          <p className="content-text" id="infotext">
            The mission of HackMHS is to create a safe and inclusive
environment that encourages learning, growth, and creativity for
students from diverse backgrounds. We are committed to
providing the best possible experience for our participants,
which includes free access to workshops, food, and guidance
from experienced mentors
          </p>
        </div>
        <hr />

        <div className="paragraph">
          <h1 className="content-header" id="info">
            Why should you sponsor us?
          </h1>
          <p className="content-text" id="infotext">
HackMHS is a free-to-join event, and we rely on sponsors to ensure its
smooth and successful execution. The funds raised are allocated towards
providing participants with food, clothing, and prizes. Your company can
support us by making monetary contributions or providing company
products and services.
          </p>
        </div>
        <hr />

          <div className="card-grid">
            <div className="card">
              <h2 className="card-title-s">Outreach</h2>
              <p className="card-text-s">
              Spread your name to students and families who come to the event.
              </p>
            </div>

            <div className="card">
              <h2 className="card-title-s">Who can attend?</h2>
              <p className="card-text-s">
                Help support a local educational event for students run by students.
              </p>
            </div>
        </div>


      </Container>
    </div>
  );
}
